<template>
    <div>
        <div class="row page-titles mx-0">
            <div class="col-md-6 p-md-0">
                <div class="welcome-text">
                    <h4>Students</h4>
                </div>
            </div>
            <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item">
                        <router-link :to="{ name: 'Dashboard-Home' }">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link :to="{ name: 'Student-Affairs-Students' }">Students</router-link>
                    </li>
                    <li class="breadcrumb-item active">Edit</li>
                </ol>
            </div>
        </div>

        <div class="row tab-content">

            <div id="list-view" class="tab-pane fade active show col-lg-9">
                <div class="card top-red-border">
                    <div class="card-header">
                        <h4 class="card-title">Edit the Student</h4>
                    </div>
                    <div class="card-body">
                        <!-- FeedBack -->
                        <feedback-messages :msgs="msgs" :errors="errors" />
                        <!-- /FeedBack -->
                        <!-- /FeedBack -->
                        <div class="basic-form">
                            <form @submit.prevent="submitFormData()" id="submitFormId">
                                <!-- Student Information -->
                                <div class="student-information">
                                    <div class="form-group row">
                                        <label class="col-md-12 col-form-label" style="color:#781b20"><i
                                                class="custom-left-border"></i> Student Information</label>
                                    </div>
                                    <div class="custom-left-tall-border">
                                        <!-- Fullname [en]-->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Name [English] <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-3">
                                                <input type="text" class="form-control" required v-if="student.user"
                                                    v-model="student.user.firstname" name="firstname"
                                                    placeholder="First Name in English">
                                            </div>
                                            <div class="col-md-3">
                                                <input type="text" class="form-control" required v-if="student.user"
                                                    v-model="student.user.middlename" name="middlename"
                                                    placeholder="Middle Name in English">
                                            </div>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required v-if="student.user"
                                                    v-model="student.user.lastname" name="lastname"
                                                    placeholder="Family Name in English">
                                            </div>
                                        </div>
                                        <!-- Fullname [Ar]-->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Name [Arabic] <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-3">
                                                <input type="text" class="form-control" required
                                                    v-model="student.firstname_ar" name="firstname_ar"
                                                    placeholder="First Name in Arabic">
                                            </div>
                                            <div class="col-md-3">
                                                <input type="text" class="form-control" required
                                                    v-model="student.middlename_ar" name="middlename_ar"
                                                    placeholder="Middle Name in Arabic">
                                            </div>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required
                                                    v-model="student.lastname_ar" name="lastname_ar"
                                                    placeholder="Family Name in Arabic">
                                            </div>
                                        </div>
                                        <!-- Email and Phone -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Email <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="email" v-if="student.user" required class="form-control"
                                                    v-model="student.user.email" name="email" placeholder="Email">
                                            </div>
                                            <label class="col-md-2 col-form-label">Phone <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" v-if="student.user" required class="form-control"
                                                    v-model="student.user.phone" name="phone" placeholder="Phone">
                                            </div>
                                        </div>
                                        <!-- Country and City of the Birth -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Country of Birth <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required v-model="student.birth_country_id"
                                                    name="birth_country_id">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="country in lists.countries" :key="country.id"
                                                        :value="country.id">{{ country.value }}
                                                    </option>
                                                </select>
                                            </div>
                                            <label class="col-md-2 col-form-label">City of Birth <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required
                                                    v-model="student.birth_city_name" name="birth_city_name"
                                                    placeholder="City of Birth">
                                            </div>
                                        </div>
                                        <!-- Age and Gender -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Age at First of Next October <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" required v-model="student.age"
                                                    name="age" placeholder="Age">
                                            </div>
                                            <label class="col-md-2 col-form-label">Gender <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required v-model="student.gender"
                                                    name="gender">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </div>
                                        </div>
                                        

                                        <!-- Second Language and Transportation -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Second Language<span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required v-model="student.second_language_id"
                                                    name="second_language_id">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="(language) in lists.languages" :key="language.id"
                                                        v-bind:value="language.id">
                                                        {{ language.value }}</option>
                                                </select>
                                            </div>
                                            <label class="col-md-2 col-form-label">Transportation <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <select class="form-control" required v-model="student.transportation_type"
                                                    name="transportation_type">
                                                    <option value="" disabled selected>Nothing Selected</option>
                                                    <option v-for="(type, index) in lists.transportation_types" :key="index"
                                                        v-bind:value="type">
                                                        {{ type }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <!-- Previous School and Last Grade -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Previous School <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" required class="form-control"
                                                    v-model="student.previous_school" name="previous_school"
                                                    placeholder="Previous School Name">
                                            </div>
                                            <label class="col-md-2 col-form-label">Last Grade <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" required class="form-control"
                                                    v-model="student.last_grade" name="last_grade"
                                                    placeholder="Last Grade Value">
                                            </div>
                                        </div>

                                        <!-- Number of Brothers and Number of Sisters -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Number of Brothers <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" required class="form-control"
                                                    v-model="student.number_of_brothers" name="number_of_brothers"
                                                    placeholder="Number of Brothers">
                                            </div>
                                            <label class="col-md-2 col-form-label">Number of Sisters <span
                                                    style="color:red">*</span></label>
                                            <div class="col-md-4">
                                                <input type="text" required class="form-control"
                                                    v-model="student.number_of_sisters" name="number_of_sisters"
                                                    placeholder="Number of Sisters">
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <!-- Attachments -->
                                <div class="Attachments" style="padding-bottom:20px">
                                    <div class="form-group row">
                                        <label class="col-md-12 col-form-label" style="color:#781b20"><i
                                                class="custom-left-border"></i> Attachments</label>
                                    </div>

                                    <div class="custom-left-tall-border">
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Student Birth Certificate <span
                                                    style="color:red">*</span>
                                                <small v-if="student.student_birth_certificate_file_path"><br><a
                                                        style="color: blue;font-style: italic;font-weight: 700;cursor: pointer;"
                                                        @click.prevent="downloadFile(student.student_birth_certificate_file_path, 'student_birth_certificate_file')"
                                                        target="_blank">Download file</a></small>
                                            </label>
                                            <div class="col-md-4">
                                                <input type="file" @change="handleFiles" accept="image/*, .pdf, .doc, .docx"
                                                    name="student_birth_certificate_file" class="dropify">

                                            </div>

                                            <label class="col-md-2 col-form-label">Student Vaccination Certificate <span
                                                    style="color:red">*</span>
                                                <small v-if="student.student_vaccination_certificate_file_path"><br><a
                                                        style="color: blue;font-style: italic;font-weight: 700;cursor: pointer;"
                                                        @click.prevent="downloadFile(student.student_vaccination_certificate_file_path, 'student_vaccination_certificate_file')"
                                                        target="_blank">Download file</a></small>
                                            </label>
                                            <div class="col-md-4"><input type="file" @change="handleFiles"
                                                    accept="image/*, .pdf, .doc, .docx"
                                                    name="student_vaccination_certificate_file" class="dropify">
                                            </div>
                                        </div>
                                         
                                        <div class="form-group row">
                                            <label v-if="student.isDivorced" class="col-md-2 col-form-label">Legal
                                                Custody <span style="color:red">*</span>
                                                <small v-if="student.legal_custody_file_path"><br><a
                                                        style="color: blue;font-style: italic;font-weight: 700;cursor: pointer;"
                                                        @click.prevent="downloadFile(student.legal_custody_file_path, 'legal_custody_file')"
                                                        target="_blank">Download file</a></small>
                                            </label>
                                            <div v-if="student.isDivorced" class="col-md-4">
                                                <input type="file" @change="handleFiles" accept="image/*, .pdf, .doc, .docx"
                                                    name="legal_custody_file" class="dropify">
                                                <br><small style="color:red">Mandatory only in case of Divorced</small>
                                            </div>

                                            <label v-if="student.isSpecialEducationNeeded"
                                                class="col-md-2 col-form-label">Special Education Report <span
                                                    style="color:red">*</span>
                                                <small v-if="student.special_education_file_path"><br><a
                                                        style="color: blue;font-style: italic;font-weight: 700;cursor: pointer;"
                                                        @click.prevent="downloadFile(student.special_education_file_path, 'special_education_file')"
                                                        target="_blank">Download file</a></small>
                                            </label>
                                            <div v-if="student.isSpecialEducationNeeded" class="col-md-4">
                                                <input type="file" @change="handleFiles" accept="image/*, .pdf, .doc, .docx"
                                                    name="special_education_file" class="dropify">
                                                <br><small style="color:red">Mandatory in case of Special Education
                                                    Supports
                                                    Check</small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Profile Picture
                                                <span style="color: red">*</span>
                                                <small v-if="student.img_path"><br><a
                                                        style="color: blue;font-style: italic;font-weight: 700;cursor: pointer;"
                                                        @click.prevent="downloadFile(student.img_path, 'img_url')"
                                                        target="_blank">view</a></small>
                                            </label>
                                            <div class="col-md-4">
                                                <input type="file" @change="handleFiles" accept="image/*" name="img_url"
                                                    class="dropify" />
                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div class="form-group row" style=" padding-top: 2rem; margin-bottom: 0px; ">
                                    <div class="col-md-12">
                                        <button :disabled="disableBtn" name="submit" type="submit" class="btn btn-primary"
                                            value="Submit" onclick="this.blur();"> Submit
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3" style="padding-bottom: 15px;">
                <img v-if="student.img_url && student.img_url != null" :src="student.img_link" style="width:100%">
                <img v-else src="~@/assets/imgs/Profile_pic_placeholder.png" style="width:100%">
            </div>
            <br>
        </div>
    </div>
</template>

<script>
import FeedbackMessages from '@/components/FeedbackMessages.vue';
import useStudent from "@/composables/students";
import {
    useRouter
} from 'vue-router'
import {
    reactive,
    ref,
    inject,
    onBeforeMount
} from 'vue';

export default {
    props: {
        id: {
            required: true,
            type: Number
        }
    },
    components: {
        FeedbackMessages
    },
    setup(props) {

        const swal = inject('$swal');
        const hasPermission = inject('hasPermission');
        const router = useRouter();
        const disableBtn = ref(false);
        var formDataObj = new FormData();

        const {
            lists,
            msgs,
            errors,
            student,
            getLists,
            getStudent,
            UpdateStudent,
            downloadStudentFile,

        } = useStudent();


        onBeforeMount(async () => {
            //first: check the user Permission
            if (!hasPermission('edit_students'))
                router.push({
                    name: '401'
                })

            await getStudent(props.id)
            getLists();
        });


        const flags = reactive({
            'stdNationalID': false,
            'stdPassport': false,
            'isStdOtherReligion': false,
        })


        const checkIfStdOtherReligion = () => {

            if (student.value.religion == 'Other')
                flags.isStdOtherReligion = true;
            else
                flags.isStdOtherReligion = false;
        }

        const checkStdNationality = (e) => {

            flags.stdNationalID = false;
            flags.stdPassport = false;

            if (e.target.options.selectedIndex > -1) {
                let identificationtype = e.target.options[e.target.options.selectedIndex].dataset
                    .identificationtype;
                if (identificationtype == 'Nstudent.')
                    flags.stdNationalID = true;
                else
                    flags.stdPassport = true;
            }

        }

        const handleFiles = async (e) => {
            let file = e.target.files[0];
            let name = e.target.name;
            formDataObj.append(name, file);
        }

        const submitFormData = async () => {

            const form = document.querySelector("#submitFormId");
            Array.from(form.elements).forEach((input) => {
                if (input.type != "file" && input.type != "submit")
                    formDataObj.append(input.name, input.value);
                input.value = "";
            });

            swal.fire({
                title: 'Please wait while submit your request.',
                allowOutsideClick: false,
                didOpen: () => {
                    swal.showLoading()
                }
            });

            errors.value = [];
            disableBtn.value = true;

            await UpdateStudent(
                formDataObj, props.id
            ).then((resp) => {
                swal.close();
                if (resp != 'fail') {
                    router.push({
                        name: 'Student-Affairs-Students',
                    })
                }
                window.scrollTo(0, 0);
                disableBtn.value = false;
            });

        }

        const downloadFile = async (filepath, filetype) => {
            await downloadStudentFile(filepath, filetype);
        }

        return {
            student,
            flags,
            lists,
            msgs,
            errors,
            disableBtn,
            submitFormData,
            handleFiles,
            checkIfStdOtherReligion,
            checkStdNationality,
            downloadFile
        };
    },
};
</script>